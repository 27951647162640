/* Raleway Google Font */
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

/* Press Start 2P Google Font */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Noto Serif Display Google Font */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@200;300;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    body {
        @apply bg-lime-100;
    }
}
